import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import InnerPageHeader from "./components/innerPageHeader"
import { scrollToElement } from "../../../../utility/utils"
import BusinessRegistrationPlan from "./components/business-registration"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import FAQList from '../../general/faqsList';
import MoreForYouComponent from "../../general/more-for-you"
import { moreForYou } from "./features"

import UpgradeAccountIcon from "../../../../../assets/img/upgrade-account.svg"
import DarkMailIcon from "../../../../../assets/img/kuda-mail-dark.inline.svg"
import GrowthIcon from "../../../../../assets/img/business-growth.inline.svg"

import EntryIllustration from "../../../../../assets/img/registration-hero-image.svg"
import EntryIllustrationBlur from "../../../../../assets/img/business/blur/reg-hero-blur.inline.svg"

import RegisterImage from "../../../../../assets/img/business/business_registration/business-account-illustration.svg"
import CustomerImage from "../../../../../assets/img/business/business_registration/happy-customer-illustration.svg"
import RequirementsImage from "../../../../../assets/img/business/business_registration/registerYourBusiness-illustration.svg"
import BenefitsImage from "../../../../../assets/img/business/business_registration/benefit-illustration.svg"

import { Img } from "react-image";

const entryContent = {
  title: (<span>
    <span className="color-secondary">Register</span>  your business to grow!
  </span>),
  subtitle: "Get your CAC registration documents and your Tax Identification Number (TIN) easily through your Kuda Business account.",
  name: "Register Your Business Now",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
}

const businessTopFeatures = [
  {
    icon: <Img src={UpgradeAccountIcon} />,
    text: `Register to upgrade your account.`,
  },
  {
    icon: <DarkMailIcon />,
    text: `Make your business official.`,
  },
  {
    icon: <GrowthIcon />,
    text: `Get access to more opportunities to grow.`,
  },
]

const requirements = {
  title: "Meet the requirements for a full business account.",
  subtitle: "With a registered business, you can upgrade to a full Kuda Business account and increase your daily transaction limit to ₦250 million.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
  name: "Register now",
  illustration: (
    <Img src={RequirementsImage} className="kuda-business-image" />
  ),
}

const customers = {
  title: "Give potential customers more confidence in your business.",
  subtitle: "Registering your business with the CAC makes it official and more trustworthy.",
  url: "",
  name: "",
  illustration: (
    <Img src={CustomerImage} className="kuda-business-image" />

  ),
}

const benefits = {
  title: "Take advantage of opportunities to upscale your business.",
  subtitle: "Get your CAC documents so you can apply for loans and grants that are exclusive to registered businesses.",
  url: "",
  name: "",
  illustration: (
    <Img src={BenefitsImage} className="kuda-business-image" />

  ),
}

const expansion = {
  title: "Register your business to grow!",
  subtitle: "Get your CAC registration documents and your Tax Identification Number (TIN) easily through your Kuda Business account.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
  name: "Start business registration",
  illustration: (
    <Img src={RegisterImage} className="kuda-business-image" />

  ),
}

const questions = [
  {
    heading: "What do I need to do to register my business?",
    list: (
      <span className="flex flex-column mt-2">
        <span className="faq-description f-16">You just need to sign up on Kuda Business.</span>

      </span>),
    index: 1
  },
  {
    heading: "How much will it cost me to register my business?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">It’ll cost you 20,000 naira to register your business name.
          Registering your company will cost you 60,000 naira.</span>

      </span>),
    index: 2
  },
  {
    heading: "What’s the difference between business name registration and company registration?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">Business name registration means registering the name under which
          you trade while company registration means registering your business as a limited liability company.

        </span>

      </div>),
    index: 3
  },
  {
    heading: "What documents or details will I get after registering my business?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description mb-3 f-16">
          If you register your business name, you’ll get a Certificate of Incorporation, a status report and a Tax Identification Number.
        </span>
        <span className="faq-description mb-3 f-16">
          If you register as a company, you’ll get a Certificate of Incorporation, Memorandum and Articles of Association, a status report and a Tax Identification Number.
        </span>

      </div>),
    index: 4
  },
  {
    heading: "How do I register my business?",
    list: (
      <div className="flex flex-column  mt-2">
        <div className="faq-description f-16">
          To register your business, follow the steps below:
        </div>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to your Kuda Business account.</div>
            <div className="mb-3">2. Click the <b>Register your business</b>banner on your Kuda Business dashboard.</div>
            <div className="mb-3">3. Click <b> Business Name Registration</b> or <b>Company Registration,</b>fill in the required details and follow the prompts on your screen.</div>
          </div>
        </span>

      </div>),
    index: 5
  },

]

const BusinessRegistration = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryIllustrationBlur} className="hero-illustration" />}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />

      <ImageDarkTextRightWithButton
        title={requirements.title}
        subtitle={requirements.subtitle}
        illustration={requirements.illustration}
        name={requirements.name}
        url={requirements.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={customers.title}
        subtitle={customers.subtitle}
        illustration={customers.illustration}
        name={customers.name}
        url={customers.url}
      // isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={benefits.title}
        subtitle={benefits.subtitle}
        illustration={benefits.illustration}
        name={benefits.name}
        url={benefits.url}
      // isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={expansion.title}
        subtitle={expansion.subtitle}
        illustration={expansion.illustration}
        name={expansion.name}
        url={expansion.url}
        isExternal={true}
      />
      <BusinessRegistrationPlan />
      <FAQList title={"Business Registration FAQS"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTABusiness />
    </Fragment>
  )
}

export default BusinessRegistration
